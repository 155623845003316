.mantine-datatable-loader {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: alpha(var(--mantine-datatable-background-color), 0.75);
  opacity: 0;
  transition: opacity 0.2s;
}

.mantine-datatable-loader-fetching {
  pointer-events: all;
  opacity: 1;
}
