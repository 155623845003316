.mantine-datatable-row-expansion-cell {
  padding: 0;
}

.mantine-datatable-row-expansion-cell-content {
  tr:not(:last-of-type) & {
    border-bottom: rem(1px) solid var(--mantine-datatable-row-border-color);
  }
  border-top: rem(1px) solid var(--mantine-datatable-row-border-color);
  [data-with-row-border] & {
    border-top: 0;
  }
}
