.mantine-datatable-header-cell-sortable {
  cursor: pointer;
  transition: background 0.2s;
  &:hover:not(:has(button:hover)) {
    background: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.mantine-datatable-header-cell-draggable {
  cursor: grab;
  border-radius: rem(6px);
  transition: all 0.2s;
  &:active {
    cursor: grabbing;
  }
  &:hover:not(:has(button:hover)) {
    background: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-6));
  }
}

.mantine-datatable-header-cell-draggable-action-icon {
  cursor: inherit;
  margin: rem(-2px) rem(2px) 0;
}

.mantine-datatable-header-cell-drag-over {
  background: light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-6));
}

.mantine-datatable-header-cell-toggleable-icon {
  transition: opacity 0.2s;
  opacity: 0.1;
}

.mantine-datatable-header-cell-toggleable {
  &:hover {
    .mantine-datatable-header-cell-toggleable-icon {
      opacity: 1;
    }
  }
}

.mantine-datatable-header-cell-resizable {
  position: relative;
}

.mantine-datatable-header-resizable-handle {
  position: absolute;
  cursor: 'col-resize';
  top: 0;
  bottom: 0;
  width: rem(8px);
  background: inherit;
  cursor: col-resize;
  z-index: 1;
  transform: translateX(50%);
  opacity: 0;
  transition: opacity 0.2s;
  &::after {
    position: absolute;
    content: '';
    top: rem(1px);
    bottom: rem(1px);
    left: rem(2px);
    border-right: rem(4px) dotted light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  &:hover::after {
    border-color: var(--mantine-primary-color-6);
  }
}

tr:hover .mantine-datatable-header-resizable-handle {
  opacity: 1;
}

.mantine-datatable-header-cell-sortable-group {
  gap: 0.25em;
}

.mantine-datatable-header-cell-sortable-text {
  min-width: 0;
  flex-grow: 1;
}

.mantine-datatable-header-cell-sortable-icon {
  transition: transform 0.2s;
}

.mantine-datatable-header-cell-sortable-icon-reversed {
  transform: scaleY(-1);
}

.mantine-datatable-header-cell-sortable-unsorted-icon {
  color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3));
  transition: color 0.2s;
  th:hover & {
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
  }
}
