.mantine-datatable-header {
  z-index: 2;
  position: sticky;
  top: 0;

  th {
    border-bottom: rem(1px) solid var(--mantine-datatable-border-color);
  }
}

.mantine-datatable-header-column-toggle-checkbox-label {
  user-select: none;
}
