.mantine-datatable-row-selector-cell {
  position: sticky;
  z-index: 1;
  width: 0;
  left: 0;
  tr[data-selected] & {
    background: inherit;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--mantine-primary-color-light);
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: calc(-1 * var(--mantine-spacing-xs));
    bottom: 0;
    border-left: 1px solid var(--mantine-datatable-row-border-color);
    width: var(--mantine-spacing-xs);
    background: var(--mantine-datatable-shadow-background-left);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  tr[data-with-row-border] &::after {
    top: rem(-1px);
    bottom: rem(-1px);
  }

  tr:last-of-type &::after {
    bottom: 0;
  }

  &[data-shadow-visible]::after {
    opacity: 1;
  }
}

.mantine-datatable-row-selector-cell-checkbox {
  cursor: pointer;
}
