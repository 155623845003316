.mantine-datatable-header-selector-cell {
  position: sticky;
  width: 0;
  left: 0;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: calc(-1 * var(--mantine-spacing-xs));
    bottom: rem(-1px);
    border-left: 1px solid var(--mantine-datatable-row-border-color);
    width: var(--mantine-spacing-xs);
    background: var(--mantine-datatable-shadow-background-left);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &[data-shadow-visible]::after {
    opacity: 1;
  }
}
