.mantine-datatable-page-size-selector-button-icon {
  margin: 0 rem(-4px) 0 rem(2px);
}

.mantine-datatable-page-size-selector-menu-arrow {
  z-index: -1;
}

.mantine-datatable-page-size-selector-active {
  --mantine-datatable-pagination-active-text-color: var(
    light-dark(
      --mantine-datatable-pagination-active-text-color-light,
      --mantine-datatable-pagination-active-text-color-dark
    ),
    var(--mantine-color-white)
  );
  --mantine-datatable-pagination-active-background-color: var(
    light-dark(
      --mantine-datatable-pagination-active-background-color-light,
      --mantine-datatable-pagination-active-background-color-dark
    ),
    var(--mantine-primary-color-filled)
  );

  opacity: 1;
  color: var(--mantine-datatable-pagination-active-text-color);
  background: var(--mantine-datatable-pagination-active-background-color);
}
